.font-link {
  font-family: "Poppins", sans-serif;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: black;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
